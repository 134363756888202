<template>
  <!--讲师列表-->
  <div class="container">
    <div class="hh_con_wrap">
      <div class="hh_con common_inner">
        <teacher-com :teacherData="teacherData"/>
        <none-data v-if="teacherData.length <= 0" :searchType="searchType"></none-data>
        <pagination class="pagination jumpBtn" v-if="totalCount > 0" :pageSize="teacherForm.pageSize"
                    :total="totalCount" @pageChange="pageChange" @sizeChange="sizeChange"/>
      </div>
    </div>
  </div>

</template>

<script>
import API from "@apis/backStageEndAPI/lecturerAPI";
import teacherCom from "@/components/studentsComponent/teacherCom";
import Pagination from '@comps/backStageComponent/pagination'
import noneData from '@comps/backStageComponent/noneData'
import CommonUtils from "@/utils";
import {EventBus} from "@assets/js/eventBus";
export default {
  name: "famousTeacher",
  components: {teacherCom, Pagination, noneData},
  data() {
    return {
      searchType: 1,
      totalCount: 0,
      teacherData: [],
      teacherForm: {
        teaName: '',
        page: 1,
        pageSize: 20,
      },
    }
  },
  watch: {
    '$store.state.student.value': {
      handler(val) {
        if (val == '') {
          this.teacherForm.teaName = ''
          this.init()
        }
      },
      deep: true,
    },
  },
  mounted() {
    if(this.$store.state.student.value) {
      this.teacherForm.teaName = this.$store.state.student.value
      this.init()
    }
    EventBus.$emit("curIndex", 3);
    if (sessionStorage.getItem('teacherData')) {
      let data = JSON.parse(sessionStorage.getItem('teacherData'))
      if (data.dataList.length > 0) {
        this.totalCount = data.rowCount
        this.teacherData = data.dataList
      }
    } else if (sessionStorage.getItem('nodata')) {
      this.teacherData = []
    } else {
      this.init()
    }
  },
  beforeDestroy() {
    sessionStorage.removeItem("teacherData");
    sessionStorage.removeItem("nodata");
  },
  methods: {
    async init() {
      let res = await API.getClientTeacher(CommonUtils.parseToParams(this.teacherForm))
      if (res && !res.status) {
        this.totalCount = res.rowCount
        this.teacherData = res.dataList
      }
    },
    pageChange(val) {
      if (val) {
        this.teacherForm.page = val
        this.init()
      }
    },
    sizeChange(val) {
      if (val) {
        this.teacherForm.page = val
        this.init()
      }
    },
  },
}
</script>
<style lang='scss' scoped>
@import "src/assets/css/common.scss";
.container {
  min-height: calc(100vh - 303px);
  width: 100%;
  background: #f7faff;
  padding-top: 20px;
  .hh_con_wrap {
    width: 100%;
  }
}
</style>
